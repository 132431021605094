<template>
 <div class="student-info-wrap">
  <h2 class="sub-title">学员信息<b class="tip">(学习账号用于app的学习与签约接收验证码，请正确填写！)</b></h2>
  <div style="margin-top: 16px">
    <el-row :gutter="24">
      <el-col style="width:306px">
        <el-form-item
          prop="stuMoblie"
          class="inline-formitem"
          :class="{noAllowed: disabledChangeStuMobile}"
        >
          <!-- <v-select
            v-if="phoneList.length"
            v-model="signUpForm.stuMoblie"
            :items="phoneList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="学习账号"
            item-text="detail"
            item-value="detail"
            outlined
            :dense="true"
            :height="42"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
            :disabled="phoneList.length == 1"
            @change="getCustomeruser"
          ></v-select> -->
          <v-text-field
            v-model.trim="signUpForm.stuMoblie"
            label="学习账号"
            hide-details="auto"
            outlined
            :dense="true"
            :height="42"
            @blur="getCustomeruser"
            :disabled="isCanStudy"
          ></v-text-field>
        </el-form-item>
      </el-col>
      <el-col style="width:306px">
        <el-form-item prop="name" class="inline-formitem">
          <v-text-field
            v-model.trim="signUpForm.name"
            label="学员姓名"
            hide-details="auto"
            outlined
            :dense="true"
            :height="42"
          ></v-text-field>
        </el-form-item>    
      </el-col>
      <el-col style="width:306px">
        <el-form-item
          prop="idCard"
          class="inline-formitem idCardBigBox"
        >
          <v-text-field
            v-model.trim="signUpForm.idCard"
            label="身份证号"
            hide-details="auto"
            outlined
            :dense="true"
            :height="42"
            @blur="idCardChange"
          ></v-text-field>
        </el-form-item>
      </el-col>
      <el-col style="width: 306px">
        <el-form-item prop="education" class="inline-formitem">
          <dict-select code="EDUCATION_TYPE">
            <v-select
              v-model="signUpForm.education"
              slot-scope="props"
              :items="props.dictData"
              :menu-props="{ bottom: true, offsetY: true }"
              label="学员学历"
              item-text="label"
              item-value="value"
              outlined
              :dense="true"
              :height="42"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
            ></v-select>
          </dict-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
          <el-form-item prop="emergencyContact" class="inline-formitem idCardBigBox">
            <v-text-field
              v-model.trim="transactionInfo.emergencyContact"
              label="紧急联系人"
              hide-details="auto"
              outlined
              :dense="true"
              :height="42"
            ></v-text-field>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="emergencyContactPhone" class="inline-formitem idCardBigBox">
            <v-text-field
              v-model.trim="transactionInfo.emergencyContactPhone"
              label="紧急联系人电话"
              hide-details="auto"
              outlined
              :dense="true"
              :height="42"
            ></v-text-field>
          </el-form-item>
        </el-col>
    </el-row>
  </div>
</div> 
</template>

<script>
import dictSelect from "components/dictSelect"
export default {
  components: { dictSelect },
  props: {
    signUpForm: Object,
    disabledChangeStuMobile: Boolean,
    phoneList: Array,
    isCanStudy: Boolean,
    transactionInfo: Object
  },
  methods: {
    getCustomeruser(...args){
      this.$emit('getCustomeruser', ...args)
    },
    idCardChange() {
      this.$emit('idcardBlur', this.signUpForm.idCard);
    },
  }
}
</script>

<style lang="scss" scoped>
.student-info-wrap {
  background: #fff;
  width: 94%;
  margin: 0 auto;
  .inline-formitem{
    width: 100%;
  }
  .tip {
    font-size: 12px;
    font-weight: normal;
    color: #f54955;
    margin-left: 6px;
  }

  .signUpForm {
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;

    .signUpFormInput {
      margin-right: 0px;
    }

    .info-item {
      min-width: 90px;
    }
  }

  .like_el_input {
    //element el_input某些情况下可能有问题，设施readonly也无法输入，模仿写一个
    height: 36px;
    width: 192px;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin: 0;

    &:focus {
      outline: none;
      border-color: #337aff;
    }
  }
}
</style>